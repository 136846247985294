
import { defineComponent, ref, watch } from 'vue'
import MyHeader from '@/components/MyHeader.vue'
import CommonFooter from '@/components/CommonFooter.vue'
import MyPage from '@/components/MyPage.vue'
import DetailPage from './detail.vue'
// 首先的从vue-router中导入useRouter
import { useRouter } from 'vue-router'
import { HARDWARES } from '@/utils/database'

export default defineComponent({
  name: 'Hardware',
  components: {
    MyHeader,
    MyPage,
    DetailPage,
    CommonFooter
  },
  setup() {
    // 实例化路由
    const router = useRouter()

    const navList: string[] = ['全部', '建筑系统', '管廊系统', '智慧教育']

    const currnetNavIndex = ref(0)

    const productList: any = ref([])

    const view = ref('list')
    view.value = router.currentRoute.value.query.type ? 'detail' : 'list'
    // 路由变化时，刷新数据
    watch(router.currentRoute, () => {
      view.value = router.currentRoute.value.query.type ? 'detail' : 'list'
    })

    function getCurrentData(module: string) {
      const result = HARDWARES.filter(item => item.module === module).map(item => {
        const devieceData = item.devieceData || {}
        return {
          type: '硬件',
          module: item.module,
          key: item.name,
          zhName: devieceData.zhName,
          enName: devieceData.enName,
          intro: devieceData.content,
          img: devieceData.img,
          sortNum: item.sortNum
        }
      });
      result.sort((a, b) => a.sortNum - b.sortNum);
      return result;
    }

    const buildingProductList: object[] = getCurrentData('建筑');
    const pipeProductList: object[] = getCurrentData('管廊');
    // const storeProductList: object[] = getCurrentData('仓储');
    // const communicationProductList: object[] = getCurrentData('通讯');
    const educationProductList: object[] = getCurrentData('教育');

    const allProductList: object[] = [...buildingProductList, ...pipeProductList, ...educationProductList]
    const currentProductPage = ref(1)
    productList.value = allProductList
    // 当前模块变化时， 修改数据
    watch(currnetNavIndex, () => {
      productList.value = currnetNavIndex.value === 0 ? allProductList : currnetNavIndex.value === 1 ? buildingProductList : currnetNavIndex.value === 2 ? pipeProductList : educationProductList
      currentProductPage.value = 1 // 修改当前页码
    })

    /**
     * @description 修改产品模块当前页码
     * @author 林育宏
     * @date 2020-10-28
     */
    function changeCurrentPage(index: number) {
      currentProductPage.value = index // 修改当前页码
    }

    // 跳转到产品设备模块详情页
    function jumpProductDetail(key: string) {
      router.push({ 
        path: 'hardware', 
        query: { type: key }
      })
    }

    function returnList() {
      view.value = 'list'
    }

    return {
      view, // 当前页面视图
      returnList, // 返回列表页
      navList, // 当前硬件产品模块列表
      currnetNavIndex, // 硬件产品模块当前项
      currentProductPage, // 当前页码
      changeCurrentPage, // 修改页码
      jumpProductDetail, // 跳转页面
      productList // 右侧产品列表
    }
  }
})
